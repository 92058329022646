<template>
  <div class="media-inquiry" data-view>
    <hero
      title='<p class="saolitalic">neera media room</p>'
      description="welcome to <span class='saoltext whitespace-nowrap'>‘neera retreat’</span> press kits. here you can access press releases, press clippings and photos to facilitate your stories."
      class="my-18 lg:mb-20 lg:mt-8"
    />
    <item-navigation :items="navigations" default-tab="neera-room-brochure" />
    <neera-grid>
      <neera-grid-row
        data-aos="fade-up"
        v-for="(item, index) in medias"
        :key="index"
        :id="item.id ? item.id : ''"
        class="grid grid-cols-1 lg:grid-cols-2"
      >
        <neera-detail-row
          :classImage="`img-para w-full${
            index == 0 ? ' rounded-tr-np-lg' : ''
          }`"
          :imageUrl="item.image_url"
        >
          <div>
            <p class="title text-2xl lg:text-np-3xl">
              {{ item.name }}
            </p>
          </div>
          <div>
            <neera-outline-button
              :staticFileSlug="item.staticFileSlug"
              :linkTo="false"
              text="download pdf"
            />
          </div>
        </neera-detail-row>
      </neera-grid-row>
    </neera-grid>

    <div class="py-20 mt-20 bg-np-fog rounded-tr-xxl" data-aos="fade-up">
      <div class="max-w-screen-lg px-4 lg:px-8 mx-auto space-y-16">
        <div class="saoltext text-5xl text-np-green text-left font-light">
          media inquiry
        </div>

        <div class="space-y-4 text-left">
          <p class="text-3xl text-np-green saoltext font-light">
            contact information
          </p>
          <div class="grid lg:grid-cols-2 gap-x-6 gap-y-8">
            <div class="space-y-1 text-np-dark font-light">
              <p>first name*</p>
              <input
                class="text-np-darkest-grey px-4 py-2 placeholder:text-np-placeholder px-4 w-full rounded border border-np-grey"
                placeholder="first name"
                required
                v-model="firstName"
              />
            </div>
            <div class="space-y-1 text-np-dark font-light">
              <p>last name*</p>
              <input
                class="text-np-darkest-grey px-4 py-2 placeholder:text-np-placeholder px-4 w-full rounded border border-np-grey"
                placeholder="last name"
                required
                v-model="lastName"
              />
            </div>
            <div class="space-y-1 text-np-dark font-light">
              <p>contact number*</p>
              <input
                class="text-np-darkest-grey placeholder:text-np-placeholder px-4 py-2 w-full rounded border border-np-grey"
                placeholder="phone number"
                required
                v-model="phoneNumber"
                min="0"
                maxlength="10"
              />
              <p v-if="phoneError" class="text-orange-700 text-xs">
                {{ phoneError }}
              </p>
            </div>
            <div class="space-y-1 text-np-dark font-light">
              <p>email*</p>
              <input
                class="text-np-darkest-grey placeholder:text-np-placeholder px-4 py-2 w-full rounded border border-np-grey"
                placeholder="email"
                required
                v-model="emailAddress"
                type="email"
              />
              <p v-if="emailError" class="text-orange-700 text-xs">
                {{ emailError }}
              </p>
            </div>
          </div>
        </div>

        <div class="space-y-4 text-left">
          <p class="text-3xl text-np-green saoltext font-light">
            media details
          </p>
          <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6 gap-y-8">
            <div class="space-y-1 text-np-dark font-light">
              <p>type of media*</p>
              <select
                v-model="mediaSelected"
                class="text-np-darkest-grey placeholder:text-np-placeholder p-2 w-full rounded border border-np-grey"
              >
                <option disabled :value="null">please select one</option>
                <option
                  v-for="(item, index) in mediaTypes"
                  :value="index"
                  :key="index"
                >
                  {{ item.mediaType }}
                </option>
              </select>
            </div>
            <div class="space-y-1 text-np-dark font-light">
              <p>references (company website or portfolio)</p>
              <input
                class="text-np-darkest-grey placeholder:text-np-placeholder p-2 w-full rounded border border-np-grey"
                placeholder="enter link"
                v-model="mediaReference"
              />
            </div>
            <div class="space-y-1 text-np-dark font-light lg:col-span-2 w-full">
              <div>
                <p>upload file (maximum file size of 2MB)</p>
              </div>
              <div class="flex space-x-2 justify-start items-center">
                <div class="inline-block">
                  <div
                    class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse hover:text-white"
                    @click="showUpload"
                  >
                    choose file
                  </div>
                </div>
                <span class="text-np-glimpse font-light">
                  {{ !isFileChoosen ? "no file chosen" : fileNames[0].name }}
                </span>
                <slot><input class="hidden" type="file" /></slot>
              </div>
            </div>
          </div>
        </div>

        <div class="stay-details space-y-10 lg:space-y-4 text-left">
          <p class="text-3xl text-np-green saoltext font-light">stay details</p>
          <div
            class="flex flex-col lg:grid lg:grid-cols-4 gap-4 overflow-hidden lg:w-full lg:mt-0 lg:mb-0 space-y-10 lg:space-y-0"
          >
            <div
              class="flex-1 flex overflow-hidden border-bottom-dash justify-between items-center"
            >
              <div class="flex flex-col overflow-hidden">
                <date-picker
                  v-model="stayDateFrom"
                  :available-dates="{
                    start: new Date(),
                    end: null,
                  }"
                  :masks="masks"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <label v-if="showLabel" class="saoltext flex w-full px-4"
                      >from</label
                    >
                    <input
                      class="bg-white border px-4 py-1 rounded saoltext"
                      placeholder="from"
                      :value="getDateFormat(inputValue)"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </date-picker>
              </div>
              <div class="flex flex-none w-8 justify-end mr-4">
                <img src="@/assets/calendar.svg" width="17" height="21" />
              </div>
            </div>
            <div
              class="flex-1 flex overflow-hidden border-bottom-dash justify-between items-center"
            >
              <div class="flex flex-col overflow-hidden">
                <date-picker
                  v-model="stayDateTo"
                  :available-dates="{
                    start: getAvailableStartEndDate(stayDateFrom),
                    end: null,
                  }"
                  :masks="masks"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-4 py-1 rounded saoltext"
                      placeholder="to"
                      :value="getDateFormat(inputValue)"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </date-picker>
              </div>
              <div class="flex flex-none w-8 justify-end mr-4">
                <img src="@/assets/calendar.svg" width="17" height="21" />
              </div>
            </div>
            <div
              class="flex-1 flex overflow-hidden border-bottom-dash justify-between items-center"
            >
              <div class="flex-1 flex flex-col">
                <select class="flex-1 saoltext" v-model="guestNumber">
                  <option :value="0" selected disabled>guest</option>
                  <option v-for="n in 15" :key="n">{{ n }}</option>
                </select>
              </div>
              <div class="flex w-8 justify-end mr-4">
                <img src="@/assets/users.svg" width="32" height="20" />
              </div>
            </div>
            <div
              class="flex-1 flex overflow-hidden border-bottom-dash justify-between items-center"
            >
              <div class="flex-1 flex flex-col">
                <select class="flex-1 saoltext" v-model="roomNumber">
                  <option :value="0" disabled selected>room</option>
                  <option v-for="n in 5" :key="n">{{ n }}</option>
                </select>
              </div>
              <div class="flex w-8 justify-end mr-4">
                <img src="@/assets/bed.svg" width="24" height="15" />
              </div>
            </div>
          </div>
        </div>

        <div class="space-y-4 text-left">
          <p class="text-3xl text-np-green saoltext font-light">your message</p>
          <div class="h-32">
            <textarea
              class="p-2 w-full h-full rounded border border-np-grey"
              v-model="message"
              placeholder="send your message to us"
            ></textarea>
          </div>
        </div>

        <div class="flex lg:w-auto lg:justify-end">
          <neera-solid-button-submit
            class="w-full lg:w-auto cursor-pointer"
            @handleSubmit="handleSubmit"
            text="submit your inquiry"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "../../components/Hero.vue";
import ItemNavigation from "@/components/ItemNavigation.vue";
import NeeraSolidButtonSubmit from "@/components/NeeraSolidButtonSubmit.vue";
import NeeraOutlineButton from "@/components/NeeraOutlineButton.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import NeeraDetailRow from "../../components/layout/NeeraDetailRow.vue";
import { DatePicker } from "v-calendar";
import dayjs from "dayjs";
import { submitInquiry } from "@/api/endpoints/inquiry";
import to from "await-to-js";
import Swal from "sweetalert2";
import AOS from "aos";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export default {
  components: {
    Hero,
    DatePicker,
    ItemNavigation,
    NeeraSolidButtonSubmit,
    NeeraOutlineButton,
    NeeraGrid,
    NeeraGridRow,
    NeeraDetailRow,
  },
  name: "MediaInquiry",
  created() {
    const title = "neera media room";
    const description =
      "welcome to ‘neera retreat’ press kits. here you can access press releases, press clippings and photos to facilitate your stories.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      navigations: [
        {
          name: "room brochure",
          link: "neera-room-brochure",
        },
        {
          name: "space brochure",
          link: "neera-space-brochure",
        },
        {
          name: "neera eco–conscious brochure",
          link: "neera-eco-conscious-brochure",
        },
      ],
      medias: [
        {
          id: "neera-room-brochure",
          name: "neera room brochure",
          link: "studio",
          image_url: require("@/assets/media-inquiry/MEDIAINQUIRY_01-room-brochure.png"),
          staticFileSlug: "neeraretreathotel-room-brochure",
        },
        {
          id: "neera-space-brochure",
          name: "neera inspirational space brochure",
          link: "deluxe",
          image_url: require("@/assets/media-inquiry/MEDIAINQUIRY_02-space-brochure.png"),
          staticFileSlug: "neeraretreathotel-inspirational-space-brochure",
        },
        {
          id: "neera-eco-conscious-brochure",
          name: "neera eco–conscious brochure",
          link: "paranomic-suite",
          image_url: require("@/assets/media-inquiry/MEDIAINQUIRY_02-ecoconscious.png"),
          staticFileSlug: "neeraretreathotel-eco-conscious-brochure",
        },
      ],
      mediaTypes: [
        {
          mediaId: 1,
          mediaType: "press",
        },
        { mediaId: 2, mediaType: "influencer" },
        { mediaId: 3, mediaType: "blogger" },
        { mediaId: 4, mediaType: "television show" },
        { mediaId: 5, mediaType: "other" },
      ],
      fileNames: [],
      files: [],
      input: null,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      roomNumber: 0,
      guestNumber: 0,
      mediaSelected: null,
      message: "",
      stayDateFrom: "",
      stayDateTo: "",
      phoneError: "",
      emailError: "",
      masks: {
        input: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    firstName(value) {
      this.firstName = value;
    },
    lastName(value) {
      this.lastName = value;
    },
    emailAddress(value) {
      this.emailAddress = value;
      this.validateEmail(value);
    },
    phoneNumber(value) {
      this.phoneNumber = value;
      this.validateMobilePhone(value);
    },
    mediaReference(value) {
      this.mediaReference = value;
    },
    stayDateFrom(value) {
      this.stayDateFrom = value;
    },
    stayDateTo(value) {
      this.stayDateTo = value;
    },
  },
  computed: {
    isFileChoosen() {
      return this.files.length > 0;
    },
  },
  methods: {
    validateMobilePhone(mobile) {
      if (mobile == "") return "";
      const mobilePattern = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
      if (mobilePattern.test(mobile)) this.phoneError = "";
      else this.phoneError = "please check your phone number";
    },
    validateEmail(email) {
      if (email == "") return "";
      const emailPattern = new RegExp(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      );
      if (emailPattern.test(email)) this.emailError = "";
      else this.emailError = "please check your email address";
    },
    async handleSubmit() {
      if (
        this.firstName == "" ||
        this.lastName == "" ||
        this.phoneNumber == "" ||
        this.emailAddress == "" ||
        this.mediaSelected == null ||
        this.stayDateFrom == "" ||
        this.stayDateTo == "" ||
        this.guestNumber == 0 ||
        this.roomNumber == 0 ||
        this.phoneError != "" ||
        this.emailError != ""
      ) {
        Swal.fire({
          imageUrl: require("@/assets/alert-fail.svg"),
          imageHeight: 104,
          imageWidth: 104,
          width: 616,
          height: 362,
          html: "<div class='space-y-4 pb-10'><p class='text-np-2xl saoltext'>your information is incomplete</p><p class='text-np-base'>please complete the required information before submitting</p></div>",
          showConfirmButton: false,
          showCloseButton: true,
        });
        return;
      }

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        contactNumber: this.phoneNumber,
        email: this.emailAddress,
        mediaType: this.mediaTypes[this.mediaSelected].mediaType,
        references: this.mediaReference ? this.mediaReference : "",
        dateFrom: this.stayDateFrom,
        dateTo: this.stayDateTo,
        guestNumber: this.guestNumber,
        roomNumber: this.roomNumber,
        message: this.message ? this.message : "",
      };

      const file = this.files.length > 0 ? this.files[0] : undefined;
      let error, response;
      if (file) {
        [error, response] = await to(submitInquiry(data, file)); // eslint-disable-line no-unused-vars
      } else {
        [error, response] = await to(submitInquiry(data, undefined));
      }
      if (!response) {
        console.log(error);
        Swal.fire({
          imageUrl: require("@/assets/alert-fail.svg"),
          imageHeight: 104,
          imageWidth: 104,
          width: 616,
          height: 362,
          html: "<div class='space-y-4 pb-10'><p class='text-np-2xl saoltext'>your information is incomplete</p><p class='text-np-base'>please complete the required information before submitting</p></div>",
          showConfirmButton: false,
          showCloseButton: true,
        });
      } else {
        Swal.fire({
          imageUrl: require("@/assets/alert-success.svg"),
          imageHeight: 104,
          imageWidth: 104,
          width: 616,
          height: 362,
          html: "<div class='space-y-4 pb-10'><p class='text-np-2xl saoltext'>your request has been sent</p><p class='text-np-base'>thank you for providing your information with us</p></div>",
          showConfirmButton: false,
          showCloseButton: true,
        });
        this.firstName = "";
        this.lastName = "";
        this.phoneNumber = "";
        this.emailAddress = "";
        this.mediaSelected = null;
        this.mediaReference = "";
        this.stayDateFrom = "";
        this.stayDateTo = "";
        this.guestNumber = 0;
        this.roomNumber = 0;
        this.message = "";
        this.files = [];
        this.fileNames = [];
      }
    },
    showUpload() {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      console.log(event);
      this.input.dispatchEvent(event);
    },
    onFileSelection() {
      // reset file list
      this.files = [];
      this.fileNames = [];
      // add all selected files
      for (let file of this.input.files) {
        if (this.validateFile(file)) {
          this.fileNames.push({ name: file.name });
          this.files.push(file);
        }
      }
      // reset file input
      this.input.value = null;
    },
    validateFile(file) {
      // If file size is more than 2MB
      if (file.size > 2 * 1024 * 1024) {
        alert("File size shouldn't more than 2MB");
        return false;
      }
      return true;
    },
    getDateFormat(dateStr) {
      if (!dateStr) {
        return "";
      }
      const day = new dayjs(dateStr, "YYYY-MM-DD");
      let value = day.format("MMM D, YYYY");
      return value.toLowerCase();
    },
    getAvailableStartEndDate(date) {
      const temp = date;
      const tomorrow = new Date(temp);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });

    // Find input
    this.input = this.$el.querySelector("input[type=file]");
    this.input.addEventListener("change", () => this.onFileSelection());

    // Set multiple attribute on input, if max is more than one
    if (this.max > 1) {
      this.input.setAttribute("multiple", "multiple");
    } else {
      this.input.removeAttribute("multiple");
    }

    if (this.value) {
      this.files = this.value;
    } else {
      this.$emit("input", []);
    }
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

.title sup {
  top: -20px;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.stay-details input,
.stay-details select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  height: 42px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}
</style>
