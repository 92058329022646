<template>
  <div class="inline-block">
    <a @click="handleSubmit">
      <div
        class="py-2 px-4 saoltext text-np-light bg-np-glimpse rounded-np-10 whitespace-nowrap hover:opacity-60"
      >
        {{ text }}
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "NeeraSolidButtonSubmit",
  props: {
    text: {
      type: [String],
    },
  },
  methods: {
    handleSubmit(value) {
      this.$emit("handleSubmit", value);
    },
  },
};
</script>
