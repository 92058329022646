<template>
  <TransitionGroup
    name="fade"
    tag="div"
    class="flex w-screen overflow-x-auto space-x-8 px-4 py-8 lg:px-32 tab-menu"
  >
    <div class="flex gap-x-8 lg:gap-x-16 mx-auto">
      <div v-for="(item, index) in items" :key="index">
        <a
          :href="`#${item.link}`"
          :class="{
            hoverable: isHovering == null && item.link == contentId,
            active: item.link == contentId,
          }"
          class="robototext text-np-base text-np-green block py-2 hover:text-np-glimpse"
          @mouseover="mouseOver(item.link)"
          @mouseleave="mouseLeave"
          >{{ item.name }}
          <div
            v-if="
              isHovering == item.link ||
              (isHovering == null && item.link == contentId)
            "
            class="active-line"
          ></div>
        </a>
      </div>
    </div>
  </TransitionGroup>
</template>
<script>
export default {
  name: "ItemNavigation",
  props: {
    items: {
      type: [Array],
    },
    defaultTab: {
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      isHover: null,
      currentTab: "",
    };
  },
  watch: {
    "$route.hash": {
      handler: async function (current_path) {
        if (current_path) this.currentTab = current_path.replace("#", "");
      },
    },
  },
  computed: {
    contentId() {
      if (!this.currentTab) return this.defaultTab;
      return this.currentTab;
    },
    isHovering() {
      return this.isHover;
    },
  },
  methods: {
    mouseOver(id) {
      this.isHover = id;
    },
    mouseLeave() {
      this.isHover = null;
    },
  },
};
</script>

<style scoped>
.hoverable {
  color: rgba(153, 78, 46, 1);
  position: relative;
}

.active {
  font-family: "Roboto Medium", Arial, sans-serif !important;
  font-weight: 700;
}

.tab-menu {
  border-top: 1px solid #cecdc8;
}
/* 
.active:before {
  content: "";
  position: absolute;
  width: 10%;
  min-width: 20px;
  height: 1px;
  bottom: -8px;
  left: 40%;
  border-bottom: 1px solid #994e2e;
} */

.active-line {
  width: 50%;
  min-width: 20px;
  height: 1px;
  margin: 0px auto;
  margin-top: 12px;
  border-bottom: 1px solid #994e2e;
}
</style>
