import apiClient from "@/api/client";

export const submitInquiry = (data, files) => {
    let formdata = new FormData()
    formdata.append("data",JSON.stringify(data))
    if(files){
        formdata.append("files.quotation",files)
    }

    return apiClient.request({
        method: 'POST',
        url : "api/media-inquiries",
        data :formdata
    });
    }